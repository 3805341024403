import React from "react";
import "../assets/css/Thanks.css";

const Thanks = () => {
  return (
    <div className="Thanks">
      <h2>Vielen Dank für deine Teilnahme an unserer Wertejagd.</h2>

      <p>Du erhälst in Kürze eine Bestätigungsmail.</p>
      <p>Weiterleitung erfolgt in Kürze ...</p>
    </div>
  );
};
export default Thanks;
