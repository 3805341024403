import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Cookies.css";

const Cookies = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem("cookiesAccepted") === "true"
  );

  const handleAcceptClick = () => {
    setCookiesAccepted(true);
    localStorage.setItem("cookiesAccepted", "true");
  };
  const handleDeclineClick = () => {
    setCookiesAccepted(false);
    localStorage.setItem("cookiesAccepted", "false");
    window.location.replace("https://www.psd-nuernberg.de/grundwerte");
  };

  useEffect(() => {
    const localCookiesAccepted =
      localStorage.getItem("cookiesAccepted") === "true";
    if (localCookiesAccepted !== cookiesAccepted) {
      setCookiesAccepted(localCookiesAccepted);
    }
  }, []);

  return (
    <>
      {cookiesAccepted ? null : (
        <div className="cookie-box">
          <article>
            <p>
              Wir nutzen Cookies auf unserer Website. Wenn Sie unter 16 Jahre
              alt sind und Ihre Zustimmung zu freiwilligen Diensten geben
              möchten, müssen Sie Ihre Erziehungsberechtigten um Erlaubnis
              bitten. Weitere Informationen über die Verwendung Ihrer Daten
              finden Sie in unserer &nbsp;
              <Link
                to="https://www.psd-nuernberg.de/service/rechtliche-hinweise/datenschutzhinweis.html"
                target="_blank"
              >
                Datenschutzerklärung
              </Link>
              .
            </p>
          </article>
          <button id="accept" onClick={handleAcceptClick}>
            Akzeptieren
          </button>
          <button id="decline" onClick={handleDeclineClick}>
            Verweigern
          </button>
        </div>
      )}
    </>
  );
};

export default Cookies;
