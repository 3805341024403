import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/Home.css";

const Home = () => {
  const toggler = () => {
    const popup = document.querySelector(".popup");
    popup.classList.toggle("show");
  };

  return (
    <div className="Home">
      <img src="/images/PSD-NBG.png" alt="" />
      <h1>Wertejagd</h1>
      {/* <p>
        Die <strong>PSD Bank Nürnberg</strong> richtet ihr Handeln nach 10
        Grundwerten aus. Finde diese Werte in den verschiedenen
        Festival-Locations, ordne sie online ihrer Spielstätte zu und sichere
        Dir damit die Chance auf tolle{" "}
        <strong onClick={toggler} style={{ textDecoration: "underline" }}>
          Gewinne
        </strong>
        .
        <br />
        <br />
        VIEL SPAß!
      </p> */}
      <p>
        Ohhhh ...
        <br />
        ... das Gewinnspiel ist schon beendet.
        <br /> Bis zum nächsten Nürnberg POP Festival am{" "}
        <strong>9. – 12. Oktober 2024.</strong>
        <br />
        <br />
        Wir freuen uns auf Dich.
        <br />
        <br /> Viele Grüße <br />
        Deine PSD Bank Nürnberg
      </p>

      <div className="popup">
        <p className="close" onClick={toggler}>
          &times;
        </p>
        <h3>Es gibt zu gewinnen:</h3>
        <ol>
          <li>1 x 350 € Reisegutschein</li>
          <li>1 x 250 € Reisegutschein</li>
          <li>1 x 150 € Reisegutschein</li>
          <li>1 x 2 NBG-POP Alle-Tage-Ticket 2024</li>
          <li>2 x 50% Rabattcode*</li>
          <li>3 x 25% Rabattcode*</li>
          <li>4 x 15% Rabattcode *</li>
        </ol>
        <p>
          bei &nbsp;
          <Link to="https://kurzurlaub.de" target="_blank">
            Kurzurlaub.de
          </Link>
          <br />
          Wir behalten uns vor, auch von anderen Reiseanbietern Gutscheine zu
          nutzen/verlosen.
          <br />
          <br />
          *Die Rabatte gelten für je 2 NBG-POP Tickets für 2024
        </p>
        d
      </div>
    </div>
  );
};

export default Home;
