import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/Participation.css";

const Participation = () => {
  return (
    <div className="participation-container">
      <h2>Bedingungen für die Teilnahme am Gewinnspiel</h2>
      <h4>1. Veranstalter </h4>
      <p>
        1.1. Veranstalter des Gewinnspiels ist die
        <br />
        <strong>
          PSD Bank Nürnberg eG <br />
          Willy-Brandt-Platz 8 <br />
          90402 Nürnberg <br />
          anlässlich des Nürnberg POP Festivals 2023.
        </strong>
      </p>
      <p>
        1.2 Gewinnspiel Site{" "}
        <Link to="https://psd-wertejagd.de">www.psd-wertejagd.de</Link> wird
        gehostet von:
        <br />
        fourplex GmbH
        <br />
        Allersberger Straße 185 / Gebäude C1
        <br />
        90461 Nürnberg Deutschland
        <br />
        <Link to="tel:+4991147790300">091147790300</Link>
        <br />
        E-Mail: &nbsp;
        <Link to="mailto:info@fourplex.de">info@fourplex.de</Link>
      </p>
      <h4>2. Teilnahmeberechtigung </h4>
      <p>
        2.1. Teilnahmeberechtigt sind alle Personen, die das 18. Lebensjahr
        vollendet haben und deren Wohnsitz in Deutschland liegt.
      </p>
      <p>
        2.2. Beschäftigte der PSD Bank Nürnberg eG sind von der Teilnahme
        ausgeschlossen.
      </p>
      <p>
        2.3. Die PSD Bank Nürnberg behält es sich vor, Teilnehmer
        auszuschließen, die sich in ihren Kommentaren beleidigend,
        diskriminierend, gewaltverherrlichend, fremdenfeindlich, pornografisch
        oder rassistisch äußern. Dasselbe gilt für Kommentare, die Wettbewerbs-,
        Marken oder Urheberrechtsverstöße enthalten oder gegen die
        Nutzungsbedingungen von Instagram verstoßen.
      </p>
      <h4>3. Teilnahme am Gewinnspiel, Dauer des Gewinnspiels</h4>
      <p>
        3.1. Die Teilnahme am Gewinnspiel setzt die Angabe von Vorname, Name und
        Email-Adresse im Gewinnspielformular auf{" "}
        <Link to="https://psd-wertejagd.de">www.psd-wertejagd.de</Link> voraus.
        Des weiteren muss die Volljährigkeit bestätigt werden.
        <br />
        <br />
        Für die Teilnahme müssen die Teilnehmenden auf der Gewinnspielseite die
        10 Grundwerte der PSD Bank Nürnberg jeweils der Spielstätte zuordnen,
        auf der sie mittels Bannerfahnen sichtbar aufgestellt sind. Mindestens 3
        Werte müssen richtig zugeordnet sein, um an der Preisauslosung
        teilzunehmen.
        <br />
      </p>
      <p>
        3.2. Die Teilnahme ist bis zum 07.10.2023, <strong>23:59 Uhr</strong>{" "}
        (UTC+2) möglich.
      </p>
      <p>3.3. Die Teilnahme am Gewinnspiel ist kostenlos.</p>
      <h4>4. Ermittlung, Gewinn und Benachrichtigung der Gewinner </h4>
      <p>
        4.1. Es gibt zu gewinnen:
        <br />
        <span className="indent">1. 1 x 350 € Reisegutschein</span>
        <br />
        <span className="indent">2. 1 x 250 € Reisegutschein</span>
        <br />
        <span className="indent">3. 1 x 150 € Reisegutschein</span>
        <br />
        bei &nbsp;
        <Link to="https://kurzurlaub.de" target="_blank">
          Kurzurlaub.de
        </Link>
        .<br />
        <br />
        Wir behalten uns vor, auch von anderen Reiseanbietern Gutscheine zu
        nutzen/verlosen.
        <br />
        <span className="indent">1. 1 x 2 NBG-POP Alle-Tage-Ticket 2024 </span>
        <br />
        <span className="indent">2. 2 x 50% Rabattcode* </span>
        <br />
        <span className="indent">3. 3 x 25% Rabattcode* </span>
        <br />
        <span className="indent">4. 4 x 15% Rabattcode*</span>
        <br />
        <br />
        *Die Rabatte gelten für je 2 NBG-POP Tickets für 2024
      </p>
      <p>
        4.2. Die Gewinner werden durch zufälliges Auslosen nach Beendigung des
        Gewinnspiels ermittelt.
      </p>
      <p>
        4.3. Die Gewinner werden bis spätestens{" "}
        <strong>13.10.2023 / 23:59 Uhr</strong> (UTC +2) ausgelost und per
        <strong>E-Mail</strong> benachrichtigt. Auf diese Nachricht muss der
        Gewinner bis <strong>20.10.2023 / 23:59 Uhr</strong> (UTC +2) antworten
        und seinen Gewinn bestätigen, sowie sein Geburtsdatum und ggf. eine
        gültige E-Mail angeben, sofern der Gewinn an eine andere E-Mail-Adresse
        zugestellt werden soll. Geht keine Bestätigung des Gewinners der PSD
        Bank Nürnberg eG binnen der vorgenannten Frist ein, so wird mittels
        Zufallsauswahl ein anderer Gewinner ermittelt. Dasselbe gilt für den
        Fall, dass der Gewinner das 18. Lebensjahr noch nicht vollendet hat.
      </p>
      <p>
        4.4. Ein Anspruch auf Auszahlung des Gewinnes als Geldwert besteht
        nicht.
      </p>
      <p>
        4.5. <strong>Veröffentlichung des Gewinners:</strong> Der Gewinner
        erklärt sich damit einverstanden, dass sein Name in unseren sozialen
        Medien sowie auf unserer Website veröffentlicht wird als auch in den
        sozialen Medien und Website des Festivalveranstalters Nürnberg-POP.
      </p>

      <h4>5. Disclaimer </h4>
      <p>
        Das Gewinnspiel steht in keiner Verbindung zu Instagram und wird in
        keiner Weise von Instagram gesponsert, unterstützt oder organisiert.
      </p>
      <h4>Datenschutz</h4>
      <p>
        6.1. Verantwortlich für die Datenverarbeitung ist die PSD Bank Nürnberg
        eG. Der Datenschutzbeauftragte kann über die E-Mail-Adresse &nbsp;
        <Link to="mailto:datenschutz@psd-nuernberg.de">
          datenschutz@psd-nuernberg.de
        </Link>
        &nbsp; erreicht werden.
      </p>
      <p>
        6.2. Personenbezogene Daten werden nicht an Dritte weitergegeben und
        dienen lediglich der Gewinnübermittlung. Personenbezogene Daten der
        Gewinner werden aus revisionstechnischen Gründen bis zu zwei Jahren bei
        uns gespeichert und anschließend gelöscht.
      </p>
      <p>
        6.3. Weitere Informationen zum Umgang mit personenbezogenen Daten,
        insbesondere zu den Betroffenenrechten, sind auf der Website unter
        &nbsp;
        <Link
          to="https://www.psd-nuernberg.de/service/rechtliche-hinweise/datenschutzhinweis.html"
          target="_blank"
        >
          https://www.psd-nuernberg.de/service/rechtliche-hinweise/datenschutzhinweis.html
        </Link>
        &nbsp; veröffentlicht.
      </p>

      <h4>7. Sonstiges </h4>
      <p>7.1. Der Rechtsweg ist ausgeschlossen. </p>
      <p>
        7.2. Die PSD Bank Nürnberg eG behält sich vor, diese
        Teilnahmebedingungen im Laufe des Gewinnspiels anzupassen.
      </p>
      <p>
        7.3 Das Gewinnspiel wird auf den Social-Media-Kanälen der PSD Bank
        Nürnberg und von Nürnberg POP veröffentlicht.
      </p>
    </div>
  );
};

export default Participation;
