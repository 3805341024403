import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "./components/Home";
import Form from "./components/Form";
import Thanks from "./components/Thanks";
import Footer from "./components/Footer";
import Participation from "./components/Participation";
import Cookies from "./components/Cookies";

function App() {
  const [shiftValue, setShiftValue] = useState(-(100 / 6));
  const [showBackButton, setShowBackButton] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(true);

  useEffect(() => {
    if (shiftValue === -(100 / 6)) {
      setShowBackButton(false);
    } else if (shiftValue === -((100 / 6) * 4)) {
      setShowContinueButton(false);
      setShowBackButton(true);
    } else if (shiftValue === -((100 / 6) * 5)) {
      setShowContinueButton(false);
      setShowBackButton(false);
    } else if (shiftValue === 0) {
      setShowContinueButton(true);
    } else {
      setShowBackButton(true);
      setShowContinueButton(true);
    }
  }, [shiftValue]);

  const handleBack = () => {
    if (shiftValue < 0) {
      setShiftValue(shiftValue + 100 / 6);
    }
  };

  const handleContinue = () => {
    if (shiftValue > -((100 / 6) * 5)) {
      setShiftValue(shiftValue - 100 / 6);
    }
  };

  const handleButtonClick = () => {
    setShiftValue(0);
  };

  return (
    <div className="App">
      <Router>
        <div
          className="main-container"
          style={{ transform: `translateX(${shiftValue}%)` }}
        >
          <Participation />
          <Home />
          <Form handleContinue={handleContinue} />
          <Thanks />
        </div>

        {/* <div className="controls">
          <button
            onClick={handleBack}
            style={{ display: showBackButton ? "block" : "none" }}
          >
            Zurück
          </button>
          <button
            className="continue"
            onClick={handleContinue}
            style={{ display: showContinueButton ? "block" : "none" }}
          >
            {shiftValue === 0
              ? "zurück"
              : shiftValue === -(100 / 6)
              ? "Jetzt starten"
              : "Weiter"}
          </button>
        </div> */}
        <Cookies />
        <Footer onButtonClick={handleButtonClick} />
      </Router>
    </div>
  );
}

export default App;
