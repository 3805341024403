import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Form.css";
import axios from "axios";
import emailjs from "@emailjs/browser";
import $ from "jquery";

const Form = ({ handleContinue }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem("selectedOptions")) || Array(10).fill("")
  );

  const [heiligGeistSpital, setHeiligGeistSpital] = useState(
    localStorage.getItem("heiligGeistSpital") || ""
  );
  const [heiligGeistSpital_2, setHeiligGeistSpital_2] = useState(
    localStorage.getItem("heiligGeistSpital_2") || ""
  );
  const [katharinenruine, setKatharinenruine] = useState(
    localStorage.getItem("katharinenruine") || ""
  );
  const [kuenstlerhaus_kulturgarten, setKuenstlerhaus_kulturgarten] = useState(
    localStorage.getItem("kuenstlerhaus_kulturgarten") || ""
  );
  const [kornmarkt, setKornmarkt] = useState(
    localStorage.getItem("kornmarkt") || ""
  );

  const [ultraComix, setUltraComix] = useState(
    localStorage.getItem("ultraComix") || ""
  );
  const [korns, setKorns] = useState(localStorage.getItem("korns") || "");
  const [club_stereo, setClub_stereo] = useState(
    localStorage.getItem("club_stereo") || ""
  );
  const [neuesMuseum, setNeuesMuseum] = useState(
    localStorage.getItem("neuesMuseum") || ""
  );
  const [kuenstlerhaus, setKuenstlerhaus] = useState(
    localStorage.getItem("kuenstlerhaus") || ""
  );

  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstName") || ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("lastName") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [conditions, setConditions] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    localStorage.setItem("selectedOptions", JSON.stringify(selectedOptions));

    localStorage.setItem("heiligGeistSpital", heiligGeistSpital);
    localStorage.setItem("heiligGeistSpital_2", heiligGeistSpital_2);
    localStorage.setItem("katharinenruine", katharinenruine);
    localStorage.setItem(
      "kuenstlerhaus_kulturgarten",
      kuenstlerhaus_kulturgarten
    );
    localStorage.setItem("kornmarkt", kornmarkt);

    localStorage.setItem("ultraComix", ultraComix);
    localStorage.setItem("korns", korns);
    localStorage.setItem("club_stereo", club_stereo);
    localStorage.setItem("neuesMuseum", neuesMuseum);
    localStorage.setItem("kuenstlerhaus", kuenstlerhaus);

    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("email", email);
  }, [
    selectedOptions,
    heiligGeistSpital,
    heiligGeistSpital_2,
    katharinenruine,
    kuenstlerhaus_kulturgarten,
    kornmarkt,
    ultraComix,
    korns,
    club_stereo,
    neuesMuseum,
    kuenstlerhaus,
    firstName,
    lastName,
    email,
  ]);

  const isFormComplete = () => {
    return (
      selectedOptions.every((option) => option !== "") &&
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      conditions !== false
    );
  };

  const handleSelectChange = (index, value) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[index] = value;
    setSelectedOptions(updatedSelectedOptions);
  };

  const correctAnswers = [
    "etwas_bewirken",
    "generationenklug",
    "gerechtigkeit",
    "spass_humor",
    "aufrichtigkeit",
    "transparenz",
    "ehrlichkeit",
    "vertrauen",
    "toleranz",
    "menschlichkeit",
  ];

  const calculateScore = () => {
    let score = 0;
    for (let i = 0; i < selectedOptions.length; i++) {
      if (selectedOptions[i] === correctAnswers[i]) {
        score++;
      }
    }
    return score;
  };

  const form = useRef();

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log("Confirmation mail sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const score = calculateScore();
  console.log("Your score:", score);

  const submit_form = (e) => {
    e.preventDefault();

    sendEmail();
    handleContinue();

    setTimeout(function () {
      window.location.replace("https://www.psd-nuernberg.de/grundwerte");
    }, 5000);

    if (!isFormComplete()) {
      alert("Bitte füllen Sie alle Felder aus.");
      return;
    }
    if (
      selectedOptions.includes("") ||
      firstName === "" ||
      lastName === "" ||
      email === ""
    ) {
      alert("Bitte füllen Sie alle Felder aus.");
      return;
    }

    if (score > 2) {
      $.ajax({
        type: "POST",
        async: false,
        url: "submit.php",
        data: {
          heiligGeistSpital: heiligGeistSpital,
          heiligGeistSpital_2: heiligGeistSpital_2,
          katharinenruine: katharinenruine,
          kuenstlerhaus_kulturgarten: kuenstlerhaus_kulturgarten,
          kornmarkt: kornmarkt,
          ultraComix: ultraComix,
          korns: korns,
          club_stereo: club_stereo,
          neuesMuseum: neuesMuseum,
          kuenstlerhaus: kuenstlerhaus,
          firstName: firstName,
          lastName: lastName,
          email: email,
          score: score,
        },
      });
    }

    localStorage.clear();
  };

  const handleResetSelect = (index) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[index] = "";
    setSelectedOptions(updatedSelectedOptions);

    switch (index) {
      case 0:
        setHeiligGeistSpital("");
        break;
      case 1:
        setHeiligGeistSpital_2("");
        break;
      case 2:
        setKatharinenruine("");
        break;
      case 3:
        setKuenstlerhaus_kulturgarten("");
        break;
      case 4:
        setKornmarkt("");
        break;
      case 5:
        setUltraComix("");
        break;
      case 6:
        setKorns("");
        break;
      case 7:
        setClub_stereo("");
        break;
      case 8:
        setNeuesMuseum("");
        break;
      case 9:
        setKuenstlerhaus("");
        break;

      default:
        break;
    }
  };

  return (
    <>
      <form ref={form} onSubmit={submit_form}>
        <div className="card card-1">
          <h2>Wo ist welcher Wert zu finden?</h2>

          <div className="select-box">
            <label htmlFor="heiligGeistSpital">
              HEILIG GEIST SPITAL{" "}
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(0)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="heiligGeistSpital"
              onChange={(event) => {
                setHeiligGeistSpital(event.target.value);
                handleSelectChange(0, event.target.value);
              }}
              value={heiligGeistSpital}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="heiligGeistSpital_2">
              HEILIG GEIST SPITAL 2{" "}
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(1)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="heiligGeistSpital_2"
              onChange={(event) => {
                setHeiligGeistSpital_2(event.target.value);
                handleSelectChange(1, event.target.value);
              }}
              value={heiligGeistSpital_2}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="katharinenruine">
              KATHARINENRUINE{" "}
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(2)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="katharinenruine"
              onChange={(event) => {
                setKatharinenruine(event.target.value);
                handleSelectChange(2, event.target.value);
              }}
              value={katharinenruine}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="kuenstlerhaus_kulturgarten">
              KÜNSTLERHAUS (Kulturgarten){" "}
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(3)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="kuenstlerhaus_kulturgarten"
              onChange={(event) => {
                setKuenstlerhaus_kulturgarten(event.target.value);
                handleSelectChange(3, event.target.value);
              }}
              value={kuenstlerhaus_kulturgarten}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="kornmarkt">
              KORNMARKT{" "}
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(4)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="kornmarkt"
              onChange={(event) => {
                setKornmarkt(event.target.value);
                handleSelectChange(4, event.target.value);
              }}
              value={kornmarkt}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>
        </div>

        <div className="card card-2">
          <h2>Wo ist welcher Wert zu finden?</h2>

          <div className="select-box">
            <label htmlFor="ultraComix">
              ULTRA COMIX GALERIE G5{" "}
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(5)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="ultraComix"
              onChange={(event) => {
                setUltraComix(event.target.value);
                handleSelectChange(5, event.target.value);
              }}
              value={ultraComix}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="korns">
              KORN'S{" "}
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(6)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="korns"
              onChange={(event) => {
                setKorns(event.target.value);
                handleSelectChange(6, event.target.value);
              }}
              value={korns}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="club_stereo">
              CLUB STEREO{" "}
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(7)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="club_stereo"
              onChange={(event) => {
                setClub_stereo(event.target.value);
                handleSelectChange(7, event.target.value);
              }}
              value={club_stereo}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="neuesMuseum">
              NEUES MUSEUM (Auditorium)
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(8)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="neuesMuseum"
              onChange={(event) => {
                setNeuesMuseum(event.target.value);
                handleSelectChange(8, event.target.value);
              }}
              value={neuesMuseum}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>

          <div className="select-box">
            <label htmlFor="kuenstlerhaus">
              KÜNSTLERHAUS (Kantine)
              <button
                className="reset"
                type="button"
                onClick={() => handleResetSelect(9)}
              >
                zurücksetzen
              </button>
            </label>
            <select
              id="kuenstlerhaus"
              onChange={(event) => {
                setKuenstlerhaus(event.target.value);
                handleSelectChange(9, event.target.value);
              }}
              value={kuenstlerhaus}
            >
              <option value="" disabled>
                Wähle einen Wert aus
              </option>
              <option
                value="etwas_bewirken"
                disabled={selectedOptions.includes("etwas_bewirken")}
              >
                #etwas bewirken
              </option>
              <option
                value="ehrlichkeit"
                disabled={selectedOptions.includes("ehrlichkeit")}
              >
                #ehrlichkeit
              </option>
              <option
                value="transparenz"
                disabled={selectedOptions.includes("transparenz")}
              >
                #transparenz
              </option>
              <option
                value="spass_humor"
                disabled={selectedOptions.includes("spass_humor")}
              >
                #spaß &amp; humor
              </option>
              <option
                value="generationenklug"
                disabled={selectedOptions.includes("generationenklug")}
              >
                #generationenklug
              </option>
              <option
                value="aufrichtigkeit"
                disabled={selectedOptions.includes("aufrichtigkeit")}
              >
                #aufrichtigkeit
              </option>
              <option
                value="vertrauen"
                disabled={selectedOptions.includes("vertrauen")}
              >
                #vertrauen
              </option>
              <option
                value="toleranz"
                disabled={selectedOptions.includes("toleranz")}
              >
                #toleranz
              </option>
              <option
                value="menschlichkeit"
                disabled={selectedOptions.includes("menschlichkeit")}
              >
                #menschlichkeit
              </option>
              <option
                value="gerechtigkeit"
                disabled={selectedOptions.includes("gerechtigkeit")}
              >
                #gerechtigkeit
              </option>
            </select>
          </div>
        </div>

        <div className="card card-3">
          <h2>
            Fertig? <br /> Prima! Zeit dir die Gewinnchancen zu sichern ...
          </h2>
          <div className="select-box input-box">
            <input
              type="text"
              id="firstName"
              name="name"
              required
              placeholder="Vorname"
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
              value={firstName}
            />
          </div>

          <div className="select-box input-box">
            <input
              type="text"
              id="lastName"
              required
              placeholder="Nachname"
              onChange={(event) => {
                setLastName(event.target.value);
              }}
              value={lastName}
            />
          </div>

          <div className="select-box input-box">
            <input
              type="email"
              id="email"
              name="recipient"
              required
              placeholder="E-Mail"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
            />
          </div>

          <div className="conditions-box">
            <input
              type="checkbox"
              className="checkbox"
              onClick={() => {
                setConditions(!conditions);
              }}
              required
            />
            <label htmlFor="conditions">
              Ich bin volljährig und mit den &nbsp;
              <Link>Teilnahmebedingungen</Link> und
              <Link
                to="https://www.psd-nuernberg.de/service/rechtliche-hinweise/datenschutzhinweis.html"
                target="_blank"
              >
                &nbsp; Datenschutzbestimmungen &nbsp;
              </Link>
              einverstanden.
            </label>
          </div>

          <button type="submit" disabled={!isFormComplete()}>
            {isFormComplete() ? (
              <p>Absenden</p>
            ) : (
              <p>Bitte alle Felder ausfüllen</p>
            )}
          </button>
        </div>
      </form>
    </>
  );
};
export default Form;
